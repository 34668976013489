@font-face {
  font-family: 'Pretendard-Black';
  src: url('./fonts/Pretendard-Black.otf');
}

@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('./fonts/Pretendard-SemiBold.otf');
}

@font-face {
  font-family: 'Pretendard-Medium';
  src: url('./fonts/Pretendard-Medium.otf');
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('./fonts/Pretendard-Regular.otf');
}

@font-face {
  font-family: 'Pretendard-ExtraLight';
  src: url('./fonts/Pretendard-ExtraLight.otf');
}


body {
  margin: 0;
  font-family: 'Pretendard-Regular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}