.InputWrapper {
  position: relative;
  font-weight: 400;
  color: rgba(0, 0, 0, .87);
  font-style: normal;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 11px;
  /* margin-bottom: 2rem; */
}

/* .InputWrapper>input { */
.PopUpClass {
  margin: 0;
  max-width: 100%;
  text-align: left;
  line-height: 1.21428571em;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  -webkit-transition: border-color .1s ease, -webkit-box-shadow .1s ease;
  transition: border-color .1s ease, -webkit-box-shadow .1s ease;
  transition: box-shadow .1s ease, border-color .1s ease;
  transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  /* margin-bottom: 10px; */
}

.InputWrapper>input::-webkit-input-placeholder {
  color: rgba(191, 191, 191, .87)
}

.svgWrapper {
  position: absolute !important;
  top: 10px !important;
  right: 5px !important;
  font-size: 14px !important;
}

.dateText {
  font-weight: 600;
  font-size: 1rem;
  position: absolute;
  top: 25%;
  left: 3.13rem;
  width: 9.1875rem;
  height: 1.5rem;
}

.PopUpWrapper {
  display: none;
  position: absolute;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  z-index: 1900;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  background: #fff;
  padding: .833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15);
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15);
  left: -3.8rem;
  top: 0.38rem;
  margin-top: .5em;
  margin: 0 0 .71428571em;
  margin-right: 0;
  font-size: 1rem;
  width: 23.5rem;
}

/* .PopUpWrapper:before {
  top: -.30714286em;
  left: auto;
  right: 5rem;
  bottom: auto;
  margin-left: 0;
  -webkit-box-shadow: -1px -1px 0 0 #bababc;
  box-shadow: -1px -1px 0 0 #bababc;
}

.PopUpWrapper:before {
  position: absolute;
  content: '';
  width: .71428571em;
  height: .71428571em;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
} */

.PopUpWrapper:after {
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important
}

.PopUpWrapper input {
  font-size: 11px;
}

.open {
  display: block;
}