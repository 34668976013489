.week {
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
  padding-top: 0.25rem;
  border-style: solid none none none;
  border-width: 0.00625rem;
  border-color: #BCBCBC;
}
.weekDay {
  flex-grow: 1;
  width: 3rem;
  line-height: 3rem;
  box-sizing: border-box;
  text-align: center;
  height: 3rem;
  position: relative;
  font-weight: bold;
  font-size: 11px;
  color: #BCBCBC;
}