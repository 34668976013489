.day {
  flex-grow: 1;
  height: 3rem;
  width: 3rem;
  /* margin: 3px 0px; */
  line-height: 3rem;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.day:hover {
  border-radius: 6.1875rem;
  background-color: #90CBF911;
}

.active {
  /* background-color: rgba(200, 245, 245, 0.6); */
  border-radius: 6.1875rem;
  border: 1px solid #1E3C72;
  background-color: rgba(144, 203, 249, 0.20);
  font-weight: bold;
  color: #1E3C72;
}

.highlight {
  background-color: rgba(255, 251, 204, 0.6);
}

.empty {
  cursor: default;
  border: transparent;
}

.empty:hover {
  background-color: transparent;
}