.month {
  padding: 4px;
}

.week {
  display: flex;
  flex-direction: row;
}

.day {
  flex-grow: 1;
  height: 0.001rem;
  width: 3rem;
  margin: 3px 0px;
  line-height: 3rem;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}