.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}
.buttonGroupLeft {
  display: flex;
  cursor: pointer;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: none;
  color: rgba(0,0,0,.6);
  margin: 0 .25em 0 0;
  font-weight: 700;
  line-height: 1em;
  border-radius: .28571429rem;
}
.buttonGroupRight {
  display: flex;
  min-height: 1em;
  outline: 0;
  border: none;
  background: none;
  color: rgba(0,0,0,.6);
  margin: 0 .25em 0 0;
  font-weight: 700;
  line-height: 1em;
  text-decoration: none;
  border-radius: 10.28571429rem;
}
.button {
  cursor: pointer;
  min-height: 1em;
  outline: 0;
  background: linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%);
  color: rgba(0,0,0,.6);
  padding: 0.5rem 0.95rem;
  font-weight: 700;
  line-height: 1.5em;
  text-decoration: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  -webkit-box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset;
}
.header {
  flex-shrink: 0;
  font-weight: bold;
}